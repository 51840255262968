export default [
  {
    path: "/currency/list",
    name: "currencyList",
    component: () =>
      import(
        /* webpackChunkName: "interestRecord" */ "@/views/currency/list/index.vue"
      ),
    meta: {
      title: "货币列表",
    },
  },
  {
    path: "/currency/trader",
    name: "currencyTrader",
    component: () =>
      import(
        /* webpackChunkName: "currencytrader" */ "@/views/currency/trader/index.vue"
      ),
    meta: {
      title: "交易员列表",
    },
  },
];
